// Importing required packages
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";

// Importing required files
import "./styles/App.css";

// Importing pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import Profile from "./pages/Profile.jsx";
import Dashboard from "./Components/UserDashboard/Dashboard";

// Importing necessary components
import NotFound from "./Components/NotFound";

// Function to protect routes that require authentication
let isAuthenticated = false;
const ProtectedRoute = ({ element }) => {
  isAuthenticated = localStorage.getItem("userId"); // Check if user is logged in
  return isAuthenticated ? element : <Navigate to="/login" replace />;
};

// Function to prevent logged-in users from accessing the login page
const RestrictedRoute = ({ element }) => {
  const isAuthenticated = localStorage.getItem("userId"); // Check if user is logged in
  return isAuthenticated ? <Navigate to="/dashboard" replace /> : element;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/login"
          element={<RestrictedRoute element={<Login />} />}
        />
        <Route path="/profile" element={<Profile />} />
        <Route
          path="/dashboard"
          element={<ProtectedRoute element={<Dashboard />} />}
        />
        {/* This component would be rendered when any non-existing or falsely manipulated URL is accessed */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;

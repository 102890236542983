import React, { Fragment, useEffect, useState } from "react";
import { GoogleMap, useLoadScript, Polygon } from "@react-google-maps/api";
import "./Geofences.css";

const Geofences = () => {
  const [geofencesData, setGeofencesData] = useState([]);
  const [selectedGeofenceData, setSelectedGeofenceData] = useState(null);
  const [filteredGeofences, setFilteredGeofences] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    lat: "",
    long: "",
    radius: "",
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  // Create a circle polygon for geofences
  const createCircle = (center, radiusInKm, numPoints = 64) => {
    const earthRadius = 6371; // Earth radius in km
    const [lng, lat] = center;
    const coordinates = [];

    for (let i = 0; i <= numPoints; i++) {
      const angle = (i * 360) / numPoints;
      const radians = (Math.PI / 180) * angle;
      const dx = (radiusInKm / earthRadius) * Math.cos(radians);
      const dy = (radiusInKm / earthRadius) * Math.sin(radians);

      const newLat = lat + (dy * 180) / Math.PI;
      const newLng =
        lng + (dx * 180) / (Math.PI * Math.cos((lat * Math.PI) / 180));
      coordinates.push({ lat: newLat, lng: newLng });
    }

    coordinates.push(coordinates[0]); // Close the polygon
    return coordinates;
  };

  // Handle form submission for creating a new geofence
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const geofenceData = {
      name: formData.name.trim(),
      lat: parseFloat(formData.latitude),
      long: parseFloat(formData.longitude),
      radius: parseFloat(formData.radius),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/v0/create-geofence`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(geofenceData),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to send data: ${errorText}`);
      }

      const result = await response.json();
      console.log("Success:", result);

      setGeofencesData((prevGeofences) => [
        ...prevGeofences,
        {
          name: geofenceData.name,
          center: [geofenceData.long, geofenceData.lat],
          radius: geofenceData.radius,
        },
      ]);
      setFormData({ name: "", lat: "", long: "", radius: "" });
      setIsFormVisible(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Fetch geofences from the database
  useEffect(() => {
    const fetchGeofences = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/v0/geofences`,
          {
            credentials: "include",
          }
        );

        const geofences = await response.json();
        console.log(geofences);
        if (Array.isArray(geofences)) {
          const formattedGeofences = geofences.map((geofence) => ({
            name: geofence.name,
            center: [geofence.long, geofence.lat],
            radius: geofence.radius,
          }));
          setGeofencesData(formattedGeofences);
          setFilteredGeofences(formattedGeofences);
        } else {
          console.error("API response is not an array:", geofences);
        }
      } catch (error) {
        console.error("Failed to fetch geofences:", error);
      }
    };
    fetchGeofences();
  }, []);

  // Filter geofences based on search query
  useEffect(() => {
    if (!searchQuery.trim()) {
      setFilteredGeofences(geofencesData);
      return;
    }

    const filtered = geofencesData.filter((geo) =>
      geo.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredGeofences(filtered);
  }, [searchQuery, geofencesData]);

  // Select a geofence
  const selectGeofence = (geofence) => {
    setSelectedGeofenceData(geofence);
  };

  if (!isLoaded) return <div>Loading Map...</div>;

  return (
    <Fragment>
      <div className="geofence-header">
        <input
          type="text"
          className="search-input"
          placeholder="Search for Geofences"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="actions">
          <button className="bulk-upload">Bulk Upload</button>
          <button
            className="create-geofence"
            onClick={() => setIsFormVisible(true)}
          >
            Create new Geofence
          </button>
        </div>
      </div>

      {/* Display filtered geofences */}
      <div className="geofence-list">
        {filteredGeofences.length > 0 ? (
          filteredGeofences.map((geo, index) => (
            <div
              key={index}
              className="geofence-item"
              onClick={() => selectGeofence(geo)}
            >
              <h4>{geo.name}</h4>
              <p>Latitude: {geo.center[1]}</p>
              <p>Longitude: {geo.center[0]}</p>
              <p>Radius: {geo.radius} km</p>
            </div>
          ))
        ) : (
          <p className="no-results">No geofences found.</p>
        )}
      </div>

      {/* Geofence creation form */}
      {isFormVisible && (
        <div className="geofence-form-overlay">
          <div className="geofence-form-container">
            <h2>Create New Geofence</h2>
            <form onSubmit={handleFormSubmit}>
              <input
                type="text"
                placeholder="Geofence Name"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                required
              />
              <input
                type="number"
                step="any"
                placeholder="Latitude"
                value={formData.latitude}
                onChange={(e) =>
                  setFormData({ ...formData, latitude: e.target.value })
                }
                required
              />
              <input
                type="number"
                step="any"
                placeholder="Longitude"
                value={formData.longitude}
                onChange={(e) =>
                  setFormData({ ...formData, longitude: e.target.value })
                }
                required
              />
              <input
                type="number"
                step="any"
                placeholder="Radius (km)"
                value={formData.radius}
                onChange={(e) =>
                  setFormData({ ...formData, radius: e.target.value })
                }
                required
              />
              <button type="submit" className="submit-button">
                Save Geofence
              </button>
              <button
                type="button"
                className="cancel-button"
                onClick={() => setIsFormVisible(false)}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Google Map */}
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100vh" }}
        center={{ lat: 19.172254, lng: 72.97043 }}
        zoom={12}
      >
        {geofencesData.map((geofence, index) => (
          <Polygon
            key={index}
            paths={createCircle(geofence.center, geofence.radius)}
            options={{
              fillColor:
                geofence === selectedGeofenceData ? "#FF0000" : "#008000",
              fillOpacity: 0.3,
              strokeColor: "#000000",
              strokeOpacity: 1,
              strokeWeight: 2,
            }}
          />
        ))}
      </GoogleMap>
    </Fragment>
  );
};

export default Geofences;

import React, { useEffect, useMemo, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import {
  FaList,
  FaMapMarkedAlt,
  // FaRegCheckSquare,
  FaSearch,
} from "react-icons/fa";
import axios from "axios";
import defaultTruck from "../../../assets/default.png";
import stoppedTruck from "../../../assets/stopped.png";
import movingTruck from "../../../assets/moving.png";
import notWorkingTruck from "../../../assets/not_working.png";
import "./InfoWindow.css";

//defining truck icons for faster load
const truckIcons = {
  default: defaultTruck,
  stopped: stoppedTruck,
  moving: movingTruck,
  notWorking: notWorkingTruck,
};
// truckIcons.default.src = defaultTruck;
// truckIcons.stopped.src = stoppedTruck;
// truckIcons.moving.src = movingTruck;
// truckIcons.notWorking.src = notWorkingTruck;

//SearchBar
const SearchBar = ({ searchTerm, setSearchTerm }) => (
  <div
    style={{
      position: "sticky",
      top: 0,
      zIndex: 1000,
      backgroundColor: "#ffffff",
      padding: "10px",
      textAlign: "center",
      borderBottom: "1px solid #ddd",
    }}
  >
    <div
      style={{ position: "relative", display: "inline-block", width: "80%" }}
    >
      <FaSearch
        size={16}
        style={{
          position: "absolute",
          left: "12px",
          top: "50%",
          transform: "translateY(-50%)",
          color: "#666",
        }}
      />
      <input
        type="text"
        placeholder="Search Vehicle Number..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          width: "100%",
          padding: "10px 10px 10px 35px", // Space for the icon
          border: "1px solid #ccc",
          borderRadius: "5px",
          fontSize: "16px",
        }}
      />
    </div>
  </div>
);
//Status Bar
const StatusBar = ({ statuses, onStatusClick }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-around",
      padding: "12px",
      backgroundColor: "#ffffff",
      // borderBottom: "3px solid #007bff",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    }}
  >
    {statuses.map((status) => (
      <div
        key={status.key}
        onClick={() => onStatusClick(status.key)}
        style={{
          cursor: "pointer",
          textAlign: "center",
          padding: "8px 12px",
          // borderRadius: "8px",
          // transition: "all 0.3s ease",
          // backgroundColor: "#f8f9fa",
          // boxShadow: "2px 2px 10px rgba(0, 123, 255, 0.2)",
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.backgroundColor = "#e3f2fd")
        }
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#fff")}
      >
        <div
          style={{
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            backgroundColor: status.color,
            margin: "6px auto",
            // boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
          }}
        ></div>
        <strong style={{ color: "#007bff", fontSize: "14px" }}>
          {status.count}
        </strong>
        <div style={{ fontSize: "12px", color: "#555" }}>{status.label}</div>
      </div>
    ))}
  </div>
);

//To toggle between map and list
const ToggleButton = ({ isListView, onToggle }) => (
  <button
    onClick={onToggle}
    style={{
      position: "absolute",
      top: "10px",
      right: "20px",
      padding: "8px 12px",
      backgroundColor: "#007bff",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      zIndex: 1000,
    }}
  >
    {isListView ? <FaMapMarkedAlt size={20} /> : <FaList size={20} />}
  </button>
);

//Map View

const MapView = ({ locations, selectedVehicle, onVehicleSelect }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  const [map, setMap] = useState(null);

  // Calculate the position for the selected vehicle
  const selectedPosition = useMemo(() => {
    return selectedVehicle?.coords
      ? {
          lat: Number(selectedVehicle.coords[1]),
          lng: Number(selectedVehicle.coords[0]),
        }
      : null;
  }, [selectedVehicle?.coords]);

  useEffect(() => {
    if (selectedVehicle && map) {
      const { coords } = selectedVehicle;
      map.setCenter({ lat: coords[1], lng: coords[0] });
      map.setZoom(15);
    }
    if (map && selectedPosition) {
      map.panTo(selectedPosition);
    }
  }, [selectedPosition, selectedVehicle, map]);

  if (!isLoaded) return <div>Loading Map...</div>;

  const containerStyle = { width: "100%", height: "100vh" };
  const center = { lat: 23.4733, lng: 80.01 };

  const getTruckIcon = (status) => {
    if (status.includes("Stopped") || status.includes("PARKED"))
      return truckIcons.stopped;
    if (status.includes("No data")) return truckIcons.notWorking;
    if (status.includes("Moving")) return truckIcons.moving;
    return truckIcons.default;
  };

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={6}
      onLoad={(map) => setMap(map)}
    >
      {locations.map((location) => (
        <Marker
          key={location.vehicleNumber}
          position={{
            lat: Number(location.coords[1]),
            lng: Number(location.coords[0]),
          }}
          icon={{
            url: getTruckIcon(location.status),
            scaledSize: new window.google.maps.Size(50, 50),
          }}
          onClick={() => onVehicleSelect(location)} // Update selected vehicle
        />
      ))}

      {selectedVehicle && selectedPosition && (
        <InfoWindow
          position={selectedPosition}
          onCloseClick={() => onVehicleSelect(null)} // Clear selection
        >
          <div className="info-window">
            <div className="info-window-header">
              <strong className="vehicle-number">
                {selectedVehicle.vehicleNumber}
              </strong>
              <div className="status">
                <span className="status-label">Status:</span>{" "}
                <span className="status-value">{selectedVehicle.status}</span>
              </div>
            </div>
            <div className="info-window-body">
              <div className="location">
                <span className="location-label">Last Location:</span>{" "}
                <span className="location-value">{selectedVehicle.loc}</span>
              </div>
              <div className="timestamp">
                <span className="timestamp-label">Last Updated:</span>{" "}
                <span className="timestamp-value">
                  {selectedVehicle.timestamp.split("T")[0]}{" "}
                  {selectedVehicle.timestamp.split("T")[1].slice(0, 5)}
                </span>
              </div>
            </div>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

//All About List view
const ListView = ({ locations, onVehicleSelect }) => (
  <div style={{ padding: "10px", overflowY: "auto", maxHeight: "80vh" }}>
    <table
      style={{ width: "100%", borderCollapse: "collapse", textAlign: "left" }}
    >
      <thead>
        <tr>
          <th style={{ borderBottom: "2px solid #ddd", padding: "8px" }}>
            Vehicle Number
          </th>
          <th style={{ borderBottom: "2px solid #ddd", padding: "8px" }}>
            Status
          </th>
          <th style={{ borderBottom: "2px solid #ddd", padding: "8px" }}>
            Last Location
          </th>
          <th style={{ borderBottom: "2px solid #ddd", padding: "8px" }}>
            Last Updated
          </th>
        </tr>
      </thead>
      <tbody>
        {locations.map((loc, index) => (
          <tr
            key={loc.vehicleNumber}
            onClick={() => onVehicleSelect(loc)}
            style={{ cursor: "pointer", hover: { backgroundColor: "#f0f0f0" } }}
          >
            <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
              {loc.vehicleNumber}
            </td>
            <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
              {loc.status}
            </td>
            <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
              {loc.loc.length > 75 ? loc.loc.slice(0, 75) + "..." : loc.loc}
            </td>
            <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
              {loc.timestamp.split("T")[0] +
                " " +
                loc.timestamp.split("T")[1].slice(0, 5)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

//Rendering Component Processing i.e Selecting view hitting api
const MapPage = () => {
  const [filteredStatus, setFilteredStatus] = useState("all");
  const [isListView, setIsListView] = useState(true);
  const [locations, setLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const isRegexSearch = true;
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const handleVehicleSelect = (vehicle) => {
    setSelectedVehicle(vehicle);
    setIsListView(false); // Switch to map view
  };

  useEffect(() => {
    const preloadImages = () => {
      Object.values(truckIcons).forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    };
    preloadImages();
    const fetchLocations = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/v0/get-live-location`
        );

        const validLocations = response.data
          .filter(
            (item) =>
              item.longitude &&
              item.latitude &&
              !isNaN(item.longitude) &&
              !isNaN(item.latitude)
          )
          .map((item) => ({
            coords: [Number(item.longitude), Number(item.latitude)],
            status: item.status || "Unknown",
            vehicleNumber: item.vehicleNumber,
            loc: item.location,
            timestamp: item.lastPacketReceivedAt,
          }));
        setLocations(validLocations);
      } catch (error) {
        console.error("Failed to fetch locations", error);
      }
    };

    fetchLocations();
    // Set interval to call API every 5 minutes
    const intervalId = setInterval(fetchLocations, 300000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  //   const filteredLocations =
  //     filteredStatus === "all"
  //       ? locations
  //       : locations.filter((loc) => loc.status.includes(filteredStatus));

  // Add regex filtering
  const statusGroups = [
    { key: "all", label: "All Vehicles", color: "blue", statuses: [] },
    { key: "moving", label: "Running", color: "green", statuses: ["Moving"] },
    {
      key: "stopped",
      label: "Stopped",
      color: "red",
      statuses: ["Stopped", "PARKED"],
    },
    {
      key: "notWorking",
      label: "Not Working",
      color: "orange",
      statuses: ["No data"],
    },
    {
      key: "idling",
      label: "Idling",
      color: "black",
      statuses: ["Idling", "IDLE"],
    },
  ];
  const filteredLocations = locations.filter((loc) => {
    // Filter by status
    if (filteredStatus !== "all") {
      const group = statusGroups.find((g) => g.key === filteredStatus);
      if (!group || !group.statuses.some((s) => loc.status.includes(s))) {
        return false;
      }
    }
    // Validate coordinates
    if (
      !Array.isArray(loc.coords) ||
      loc.coords.length !== 2 ||
      isNaN(loc.coords[0]) ||
      isNaN(loc.coords[1])
    ) {
      return false;
    }

    // Filter by search term
    if (searchTerm) {
      try {
        if (isRegexSearch) {
          const regex = new RegExp(searchTerm, "i");
          return regex.test(loc.vehicleNumber);
        } else {
          return loc.vehicleNumber
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        }
      } catch (error) {
        // Fallback to simple search if regex is invalid
        return loc.vehicleNumber
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      }
    }

    return true;
  });

  const statuses = statusGroups.map((group) => ({
    ...group,
    count:
      group.key === "all"
        ? locations.length
        : locations.filter((loc) =>
            group.statuses.some((s) => loc.status.includes(s))
          ).length,
  }));

  return (
    <div>
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <StatusBar
        statuses={statuses}
        onStatusClick={(key) => setFilteredStatus(key)}
      />
      <ToggleButton
        isListView={isListView}
        onToggle={() => setIsListView((prev) => !prev)}
      />
      {isListView ? (
        <ListView
          locations={filteredLocations}
          onVehicleSelect={handleVehicleSelect}
        />
      ) : (
        <MapView
          locations={filteredLocations}
          selectedVehicle={selectedVehicle}
          onVehicleSelect={setSelectedVehicle}
        />
      )}
    </div>
  );
};

export default MapPage;

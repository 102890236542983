import React, { Fragment, useState } from "react";
import DashboardHeader from "./DashboardHeader";
import LeftSidebar from "./LeftSide/LeftSidebar.jsx";
import MainSection from "./MainSectionComponents/MainSection.jsx";

import "../../styles/Dashboard/dashboard.css";
// Import Components for dropdown items
import Map from "./MainSectionComponents/Map";
import Alerts from "./MainSectionComponents/Alerts";
import Geofences from "./MainSectionComponents/Geofences";
import TripsDashboard from "./MainSectionComponents/TripsDashboard";
import RouteManagement from "./MainSectionComponents/RouteManagement";
import RouteOptimization from "./MainSectionComponents/RouteOptimization";
import Reports from "./MainSectionComponents/Reports.jsx";
import Trips from "./MainSectionComponents/Trips.jsx";
import Vehicle from "./MainSectionComponents/Vehicle.jsx";
import User from "./MainSectionComponents/User.jsx";
import GeofenceStoppage from "./MainSectionComponents/GeofenceStoppage.jsx";
import Driver from "./MainSectionComponents/Driver.jsx";
import Payments from "./MainSectionComponents/Payments.jsx";
import TyreMaintenance from "./MainSectionComponents/TyreMaintenance.jsx";
import VehicleService from "./MainSectionComponents/VehicleService.jsx";
import ManageSubscription from "./MainSectionComponents/ManageSubscription.jsx";
import VeyzaSupport from "./MainSectionComponents/VeyzaSupport.jsx";
import UnscheduledStoppage from "./MainSectionComponents/UnscheduledStoppage.jsx";
import DailyRunningKm from "./MainSectionComponents/DailyRunningKm.jsx";
import Workshop from "./MainSectionComponents/Workshop.jsx";
import Idling from "./MainSectionComponents/Idling.jsx";
import DriverTraining from "./MainSectionComponents/DriverTraining.jsx";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import VehicleDocument from "./MainSectionComponents/VehicleDocument.jsx";
import Payout from "./MainSectionComponents/Payout.jsx";

const Dashboard = () => {
  const [activeComponent, setActiveComponent] = useState("Map View");
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  // Mapping dropdown items to their respective components
  const componentMap = {
    "Map View": <Map />,
    Alerts: <Alerts />,
    Geofences: <Geofences />,
    "Trips Dashboard": <TripsDashboard />,
    "Route Management": <RouteManagement />,
    "Route Optimization": <RouteOptimization />,
    Reports: <Reports />,
    Trips: <Trips />,
    Vehicle: <Vehicle />,
    User: <User />,
    Driver: <Driver />,
    Payments: <Payments />,
    Idling: <Idling />,
    "Manage Subscription": <ManageSubscription />,
    "Veyza Support": <VeyzaSupport />,
    "Tyre Maintenance": <TyreMaintenance />,
    "Unscheduled Stoppage": <UnscheduledStoppage />,
    "Daily Running Km": <DailyRunningKm />,
    "Geofence Stoppage": <GeofenceStoppage />,
    "Driver Training": <DriverTraining />,
    Workshop: <Workshop />,
    "Vehicle Service": <VehicleService />,
    "Vehicle Document": <VehicleDocument />,
    Payout: <Payout />,
  };

  // Function to toggle sidebar visibility
  const toggleSidebarVisibility = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  return (
    <Fragment>
      <DashboardHeader />
      <div className="dashboard-container">
        {/* Ensure a single toggle button */}
        <button
          className={`toggle-button ${!isSidebarVisible ? "move-left" : ""}`}
          onClick={toggleSidebarVisibility}
        >
          {isSidebarVisible ? <BiLeftArrow /> : <BiRightArrow />}
        </button>

        <div
          className={`dashboard-body ${
            isSidebarVisible ? "sidebar-visible" : "sidebar-hidden"
          }`}
        >
          {isSidebarVisible && (
            <LeftSidebar
              setActiveComponent={setActiveComponent}
              isVisible={isSidebarVisible}
            />
          )}
          <div className="dashboard-content">
            <MainSection content={componentMap[activeComponent]} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;

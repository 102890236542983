import React, { useState, useEffect, useRef } from "react";
import { FaBell } from "react-icons/fa"; // Import bell icon
import logo from "../../assets/logo.png";
import "../../styles/Dashboard/dashboard.css";
import { Link, useNavigate } from "react-router-dom";

const DashboardHeader = () => {
  const navigate = useNavigate();
  const [openDropdown, setOpenDropdown] = useState(null); // Track open dropdown
  const [selectedCountry, setSelectedCountry] = useState({
    name: "India",
    flag: "🇮🇳",
  }); // Track selected country
  const [notifications, setNotifications] = useState([
    "Notification 1",
    "Notification 2",
  ]); // Track notifications
  const dropdownRef = useRef(null);

  const countries = [
    { name: "India", flag: "🇮🇳" },
    { name: "UAE", flag: "🇦🇪" },
  ];

  // Toggle dropdown visibility
  const toggleDropdown = (menu) => {
    setOpenDropdown((prevMenu) => (prevMenu === menu ? null : menu));
  };

  // Handle selecting a country
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setOpenDropdown(null); // Close the dropdown
  };

  // Clear all notifications
  const clearNotifications = () => {
    setNotifications([]); // Empty the notifications array
  };
  //logout function
  const logout = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/v0/account/logout`,
        {
          method: "POST",
          credentials: "include", // Ensure cookies are sent with the request
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Logout failed");
      }

      // Clear local storage or any user-related data
      localStorage.removeItem("userId");

      // Redirect to home page
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error.message);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <nav className="dashboard-navbar" ref={dropdownRef}>
      {/* Logo */}
      <div className="navbar-logo">
        <img src={logo} alt="Venzaya Logo" />
      </div>

      {/* Dropdown Menus */}
      <ul className="dashboard-navbar-links">
        {/* Country Dropdown */}
        <li className="dashboard-nav-item">
          <button
            className="dashboard-nav-button"
            onClick={() => toggleDropdown("country")}
          >
            {selectedCountry.flag} {selectedCountry.name}
            <span
              className={`dashboard-dropdown-arrow ${
                openDropdown === "country" ? "open" : ""
              }`}
            />
          </button>
          {openDropdown === "country" && (
            <div className="dashboard-dropdown-menu">
              {countries
                .filter((country) => country.name !== selectedCountry.name) // Filter out selected country
                .map((country) => (
                  <div
                    key={country.name}
                    className="dashboard-dropdown-item"
                    onClick={() => handleCountrySelect(country)}
                  >
                    {country.flag} {country.name}
                  </div>
                ))}
            </div>
          )}
        </li>

        {/* Notifications Dropdown */}
        <li className="dashboard-nav-item">
          <button
            className="dashboard-nav-button"
            onClick={() => toggleDropdown("notifications")}
          >
            <FaBell className="notification-icon" />
            {notifications.length > 0 && (
              <span className="notification-badge">{notifications.length}</span>
            )}
            <span
              className={`dashboard-dropdown-arrow ${
                openDropdown === "notifications" ? "open" : ""
              }`}
            />
          </button>
          {openDropdown === "notifications" && (
            <div className="dashboard-dropdown-menu">
              <div className="dashboard-dropdown-header">
                <strong>Notifications</strong>
                <button
                  className="clear-all-button"
                  onClick={clearNotifications}
                >
                  Clear All
                </button>
              </div>
              {notifications.length > 0 ? (
                notifications.map((notification, index) => (
                  <div key={index} className="dashboard-dropdown-item">
                    {notification}
                  </div>
                ))
              ) : (
                <div className="dashboard-dropdown-empty">
                  No Live Alerts/Notifications to display
                </div>
              )}
            </div>
          )}
        </li>

        {/* Account Dropdown */}
        <li className="dashboard-nav-item">
          <button
            className="dashboard-nav-button"
            onClick={() => toggleDropdown("account")}
          >
            Account
            <span
              className={`dashboard-dropdown-arrow ${
                openDropdown === "account" ? "open" : ""
              }`}
            />
          </button>
          {openDropdown === "account" && (
            <div className="dashboard-dropdown-menu">
              <Link to="/profile" className="dashboard-dropdown-item">
                Profile
              </Link>
              <div className="dashboard-dropdown-item" onClick={logout}>
                Logout
              </div>
            </div>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default DashboardHeader;

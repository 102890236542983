import React, { Fragment, useState } from "react";
import LoginHeader from "../Components/LoginHeader";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";

const Login = () => {
  // storing email and password before sending it for verification
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  //to handle redirect after login
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    // login logic here

    // for demo purpose, we are using predefined values
    // if (
    //   // verifing with predefined value stored in env file for safety purpose
    //   email === process.env.REACT_APP_LOGIN_EMAIL &&
    //   password === process.env.REACT_APP_LOGIN_PASSWORD
    // ) {
    //   navigate("/dashboard");
    // } else {
    //   // if not correct credentials
    //   alert("Invalid email or password");
    // }

    // actual implementation
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/v0/account/login`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include", // To send cookies
          body: JSON.stringify({ email, password }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Login failed");
      }

      // Store token if needed (already handled in cookies)
      localStorage.setItem("userId", data.userId);

      navigate("/dashboard");
    } catch (error) {
      setError(error.message);
    }
  };

  // to see password before submitting
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <Fragment>
      {/*Header on login page */}
      <LoginHeader />

      {/*Login Page */}
      <div className="login-container">
        <div className="login-background"></div>
        <div className="login-box">
          <h1 className="login-title">Login</h1>
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleSubmit} className="login-form">
            {/*Login form components */}
            <div className="form-group">
              {/*Email */}
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
            </div>

            <div className="form-group">
              {/*Password */}
              <label htmlFor="password">Password</label>
              <div className="password-wrapper">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  required
                />

                {/*To See Password */}
                <button
                  type="button"
                  className="toggle-password"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? "🙈" : "👁️"}
                </button>
              </div>
            </div>
            {/* To submit credentials for verification */}
            <button type="submit" className="login-button">
              Log In
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Login;

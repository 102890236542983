import React, { useState, useEffect } from "react";
import "./UnscheduledStoppage.css";

const UnscheduledStoppage = () => {
  const [timeDurations, setTimeDurations] = useState([
    { label: "<20 Min", count: 0 },
    { label: "20-60 Min", count: 0 },
    { label: ">1 Hrs", count: 0 },
  ]);
  const [isEditing, setIsEditing] = useState(false);
  const [unscheduledStoppages, setUnscheduledStoppages] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc"); // Ascending/Descending
  const [sortColumn, setSortColumn] = useState(null); // Track sorting column

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/v0/dailyStatus`
        );
        const data = await response.json();

        // Process time durations
        const durationCounts = { "<20 Min": 0, "20-60 Min": 0, ">1 Hrs": 0 };
        data.forEach((item) => {
          item.unscheduledStops.forEach((stop) => {
            if (stop.totalTime < 20) durationCounts["<20 Min"]++;
            else if (stop.totalTime >= 20 && stop.totalTime < 60)
              durationCounts["20-60 Min"]++;
            else durationCounts[">1 Hrs"]++;
          });
        });

        setTimeDurations([
          { label: "<20 Min", count: durationCounts["<20 Min"] },
          { label: "20-60 Min", count: durationCounts["20-60 Min"] },
          { label: ">1 Hrs", count: durationCounts[">1 Hrs"] },
        ]);

        setUnscheduledStoppages(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDurationChange = (index, value) => {
    const updatedDurations = [...timeDurations];
    updatedDurations[index].label = value;
    setTimeDurations(updatedDurations);
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  // Sorting Function for Any Column
  const handleSortByColumn = (column) => {
    const sortedData = [...unscheduledStoppages].sort((a, b) => {
      let valueA, valueB;

      if (column === "unscheduledTime") {
        valueA = a.unscheduledStops.reduce(
          (sum, stop) => sum + stop.totalTime,
          0
        );
        valueB = b.unscheduledStops.reduce(
          (sum, stop) => sum + stop.totalTime,
          0
        );
      } else if (column === "geoFenceTime") {
        valueA = a.geoFenceStops.reduce(
          (sum, stop) => sum + (stop.totalTime || 0),
          0
        );
        valueB = b.geoFenceStops.reduce(
          (sum, stop) => sum + (stop.totalTime || 0),
          0
        );
      } else if (column === "idlingTime") {
        valueA = a.idlingStops.reduce(
          (sum, stop) => sum + (stop.totalTime || 0),
          0
        );
        valueB = b.idlingStops.reduce(
          (sum, stop) => sum + (stop.totalTime || 0),
          0
        );
      } else if (column === "dailyRunningKM") {
        valueA = a.dailyRunning.reduce((sum, run) => sum + (run.km || 0), 0);
        valueB = b.dailyRunning.reduce((sum, run) => sum + (run.km || 0), 0);
      }

      return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setUnscheduledStoppages(sortedData);
    setSortColumn(column);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc"); // Toggle sorting order
  };

  return (
    <div className="dashboard-container">
      {/* Unscheduled Stoppage Section */}
      <div className="unscheduled-stoppage">
        <div className="table-header">
          <h2>Unscheduled Stoppage</h2>
          <button onClick={handleEditToggle}>
            {isEditing ? "Save Changes" : "Customize"}
          </button>
        </div>
        <table>
          <thead>
            <tr>
              <th>Time Duration</th>
              <th>Vehicles</th>
            </tr>
          </thead>
          <tbody>
            {timeDurations.map((duration, index) => (
              <tr key={index}>
                <td>
                  {isEditing ? (
                    <input
                      type="text"
                      value={duration.label}
                      onChange={(e) =>
                        handleDurationChange(index, e.target.value)
                      }
                    />
                  ) : (
                    duration.label
                  )}
                </td>
                <td>{duration.count}</td>
              </tr>
            ))}
            <tr className="grand-total">
              <td>Grand total</td>
              <td>{timeDurations.reduce((sum, d) => sum + d.count, 0)}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* List View Table */}
      <div className="list-view">
        <table>
          <thead>
            <tr>
              <th>Vehicle No</th>
              <th>Unscheduled Stoppage</th>
              <th
                onClick={() => handleSortByColumn("unscheduledTime")}
                style={{ cursor: "pointer" }}
              >
                Time Duration{" "}
                {sortColumn === "unscheduledTime"
                  ? sortOrder === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </th>
              <th>Geofence Stoppage</th>
              <th
                onClick={() => handleSortByColumn("geoFenceTime")}
                style={{ cursor: "pointer" }}
              >
                Time Duration{" "}
                {sortColumn === "geoFenceTime"
                  ? sortOrder === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </th>
              <th>Idling</th>
              <th
                onClick={() => handleSortByColumn("idlingTime")}
                style={{ cursor: "pointer" }}
              >
                Time Duration{" "}
                {sortColumn === "idlingTime"
                  ? sortOrder === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </th>
              <th>Daily Running KM</th>
              <th
                onClick={() => handleSortByColumn("dailyRunningKM")}
                style={{ cursor: "pointer" }}
              >
                KM{" "}
                {sortColumn === "dailyRunningKM"
                  ? sortOrder === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </th>
            </tr>
          </thead>
          <tbody>
            {unscheduledStoppages.map((data, index) => (
              <tr key={index}>
                <td>{data.vehicleNumber}</td>
                <td>{data.unscheduledStops.length}</td>
                <td>
                  {data.unscheduledStops.reduce(
                    (sum, stop) => sum + stop.totalTime,
                    0
                  )}{" "}
                  min
                </td>
                <td>{data.geoFenceStops.length}</td>
                <td>
                  {data.geoFenceStops.reduce(
                    (sum, stop) => sum + (stop.totalTime || 0),
                    0
                  )}{" "}
                  min
                </td>
                <td>{data.idlingStops.length}</td>
                <td>
                  {data.idlingStops.reduce(
                    (sum, stop) => sum + (stop.totalTime || 0),
                    0
                  )}{" "}
                  min
                </td>
                <td>{data.dailyRunning.length}</td>
                <td>
                  {data.dailyRunning.reduce(
                    (sum, run) => sum + (run.km || 0),
                    0
                  )}{" "}
                  km
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UnscheduledStoppage;
